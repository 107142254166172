import React from 'react'
import { graphql } from 'gatsby'

import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import ProjectList from 'components/ProjectList'

const Home = ({ data }) => (
  <Section>
    <Wrapper>
      <ProjectList data={data} />
    </Wrapper>
  </Section>
)

export default Home

export const query = graphql`
  query {
    tdbank: file(relativePath: { eq: "tdbank/1.webp" }) {
      childImageSharp {
        fluid (webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pceats: file(relativePath: { eq: "pceats/1.webp" }) {
      childImageSharp {
        fluid (webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hyperlocal: file(relativePath: { eq: "hyperlocal/1.webp" }) {
      childImageSharp {
        fluid (webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pebble: file(relativePath: { eq: "pebble/1.webp" }) {
      childImageSharp {
        fluid (webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    strix: file(relativePath: { eq: "strix/1.webp" }) {
      childImageSharp {
        fluid (webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cab: file(relativePath: { eq: "cab/1.webp" }) {
      childImageSharp {
        fluid (webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    peel: file(relativePath: { eq: "peel/1.webp" }) {
      childImageSharp {
        fluid (webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sukiyaki: file(relativePath: { eq: "sukiyaki/1.webp" }) {
      childImageSharp {
        fluid (webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    vynl: file(relativePath: { eq: "vynl/1.webp" }) {
      childImageSharp {
        fluid (webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    phantom: file(relativePath: { eq: "phantom/1.webp" }) {
      childImageSharp {
        fluid (webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    libra: file(relativePath: { eq: "libra/1.webp" }) {
      childImageSharp {
        fluid (webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    expo: file(relativePath: { eq: "expo/1.webp" }) {
      childImageSharp {
        fluid (webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    clark: file(relativePath: { eq: "clark/1.webp" }) {
      childImageSharp {
        fluid (webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`