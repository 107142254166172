import React, { useState, useContext } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { animationTime, animationCurve } from 'styles/variables'
import { media } from 'styles/media'
import { black } from 'styles/colors'

import Heading from 'components/Heading'

const Container = styled.div`
  position: static;
`

const Project = styled(Link)`
  display: table;
`

const NameContainer = styled(Heading)`
  position: relative;
  margin-bottom: 0;
  z-index: 10;

  ${(props) =>
    props.aNameIsHovered &&
    css`
      opacity: 0.15;
    `}

  &:hover {
    opacity: 1;
  }
`

const FigureWrap = styled.div`
  ${media.medium`
    position: fixed;
    left: 35%;
    right: 5%;
    top: 10%;
    bottom: 0;
    background-color: ${black};
    opacity: 0;
    visibility: hidden;

    ${Project}:hover & {
      opacity: 1;
      visibility: visible;
    }
  `};
`

const Figure = styled(Img)`
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const HoveredContext = React.createContext()

const Name = ({ children }) => {
  const { setNameHovered, nameHovered } = useContext(HoveredContext)

  return (
    <NameContainer
      onMouseEnter={() => setNameHovered(true)}
      onMouseLeave={() => setNameHovered(false)}
      aNameIsHovered={nameHovered}
    >
      {children}
    </NameContainer>
  )
}

const ProjectList = ({ data }) => {
  const [nameHovered, setNameHovered] = useState(false)

  return (
    <HoveredContext.Provider value={{ setNameHovered, nameHovered }}>
      <Container>
        <Project to="/projects/tdbank">
          <Name>TD Banking</Name>
          <FigureWrap className="u-hide-medium-down">
            <Figure fluid={data.tdbank.childImageSharp.fluid} alt="TD App" />
          </FigureWrap>
        </Project>
        <Project to="/projects/pceats">
          <Name>PC Eats</Name>
          <FigureWrap className="u-hide-medium-down">
            <Figure fluid={data.pceats.childImageSharp.fluid} alt="PC Eats" />
          </FigureWrap>
        </Project>
        <Project to="/projects/hyperlocal">
          <Name>Hyperlocal</Name>
          <FigureWrap className="u-hide-medium-down">
            <Figure
              fluid={data.hyperlocal.childImageSharp.fluid}
              alt="Hyperlocal"
            />
          </FigureWrap>
        </Project>
        <Project to="/projects/pebble">
          <Name>Pebble</Name>
          <FigureWrap className="u-hide-medium-down">
            <Figure fluid={data.pebble.childImageSharp.fluid} alt="Pebble" />
          </FigureWrap>
        </Project>
        <Project to="/projects/strix">
          <Name>BRP Strix</Name>
          <FigureWrap className="u-hide-medium-down">
            <Figure fluid={data.strix.childImageSharp.fluid} alt="Strix" />
          </FigureWrap>
        </Project>
        <Project to="/projects/cab">
          <Name>Cab</Name>
          <FigureWrap className="u-hide-medium-down">
            <Figure fluid={data.cab.childImageSharp.fluid} alt="Cab" />
          </FigureWrap>
        </Project>
        <Project to="/projects/peel">
          <Name>Peel</Name>
          <FigureWrap className="u-hide-medium-down">
            <Figure fluid={data.peel.childImageSharp.fluid} alt="Peel" />
          </FigureWrap>
        </Project>
        <Project to="/projects/sukiyaki">
          <Name>Sukiyaki</Name>
          <FigureWrap className="u-hide-medium-down">
            <Figure
              fluid={data.sukiyaki.childImageSharp.fluid}
              alt="Sukiyaki"
            />
          </FigureWrap>
        </Project>
        <Project to="/projects/vynl">
          <Name>Vynl</Name>
          <FigureWrap className="u-hide-medium-down">
            <Figure fluid={data.vynl.childImageSharp.fluid} alt="Vynl" />
          </FigureWrap>
        </Project>
        <Project to="/projects/phantom">
          <Name>Phantom</Name>
          <FigureWrap className="u-hide-medium-down">
            <Figure fluid={data.phantom.childImageSharp.fluid} alt="Phantom" />
          </FigureWrap>
        </Project>
        <Project to="/projects/libra">
          <Name>Libra</Name>
          <FigureWrap className="u-hide-medium-down">
            <Figure fluid={data.libra.childImageSharp.fluid} alt="Libra" />
          </FigureWrap>
        </Project>
        <Project to="/projects/expo">
          <Name>Expo</Name>
          <FigureWrap className="u-hide-medium-down">
            <Figure fluid={data.expo.childImageSharp.fluid} alt="Expo" />
          </FigureWrap>
        </Project>
        <Project to="/projects/clark">
          <Name>Clark</Name>
          <FigureWrap className="u-hide-medium-down">
            <Figure fluid={data.clark.childImageSharp.fluid} alt="Clark" />
          </FigureWrap>
        </Project>
      </Container>
    </HoveredContext.Provider>
  )
}

export default ProjectList
